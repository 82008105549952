<template>
	<div
		v-if="is_view"
		class="full_layer_wrap"
	>
		<div
			v-if="wallet && wallet.address"
			class="full_layer_container"
		>
			<div
				class="full_layer_wrap_inner"
			>
				<article class="full_view">
					<div
						class="nft_card nft_qr"
					>
						<h3 class="pop_tit">{{  (token.label).toUpperCase() }} {{  $language.common.deposit }}</h3>
						<p class="qr_tit"></p>

						<div>
							<div
								class="flex-row justify-center items-center"
							>
								<qr-code :text="wallet.address" :size="200"></qr-code>
							</div>

							<div style="width: 200px; text-align: center; margin: 0 auto">
								<button @click="copy" class="flex-row justify-space-between mt-10 overflow-hidden">
									<div class="mr-10 text-left mr-10" style="width: 170px; word-break: break-all; text-align: left; font-size: 11px">{{ wallet.address }}</div>
									<v-icon class="flex-1">mdi mdi-content-copy</v-icon>
								</button>
							</div>
						</div>
						<div
							v-if="false"
							class="nft_card_qr_area"
							style="padding: 30px 0 0"
						>
							<p style="font-size: 14px" class="error">{{  $language.common.invalid_wallet }}</p>
						</div>
					</div>
					<div class="btn_wrap">
						<button class="pop_btn btn_fill_blue" @click="$emit('click')">{{  $language.common.ok }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>

import Vue from 'vue'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

export default {
	name: 'mafia035'
	, props: ['user', 'blockchain_div_code', 'token']
	, data: function(){
		return {
			wallet: {}
			, is_view: false
		}
	}
	, methods: {
		clipBoard: function (val){
			const t = document.createElement("textarea");
			document.body.appendChild(t);

			t.value = val;
			t.select();
			t.setSelectionRange(0, 9999)
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy: function (){
			this.clipBoard(this.wallet.address);
			this.$bus.$emit('notify',  { type: 'success', message: this.$language.nft.copied_wallet_address})
		}

		,getWalletAddress: async function(){
			try {
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_address
					, data: {
						member_number: this.user.member_number
						, blockchain_div_code: this.blockchain_div_code
					}
					, type: true
				})

				if(result.success){
					this.is_view = true
					this.$set(this.wallet, 'address', result.data.wallet_address)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.is_view = false
				this.$emit('click')
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}

	, created() {
		this.getWalletAddress()
	}
}

</script>