<template>
	<div
		v-if="is_view"
		class="full_layer_wrap"
	>
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article class="full_view">
					<div class="nft_card">
						<h3 class="pop_tit">{{  (wallet.label).toUpperCase() }} {{  $language.common.withdraw }}</h3>
						<div class="input_area">
							<div class="coin_logo">
								<img :src="require('@/assets/image/MAFI_60_g.png')" alt="MAFICOIN">
							</div>
							<div class="bright_input pt-20">
								<input
									v-model="item.address"
									type="text" :placeholder="$language.nft.address_withdraw_require"
									:rules="[$rules.only_num_alpha(item, 'address')]"
									maxlength="255"
									@change="getTransCommis"
								>
							</div>
							<div class="bright_input flex pt-10">
								<input
									v-model="item.amount"
									type="number" :placeholder="$language.nft.amount_withdraw_require"
									:rules="[$rules.max(item, 'amount', 25)]"
								>
								<button
									@click="max"
									class="btn_l btn_fill_blue ml-10 flex_shrink"
								>{{  $language.common.max }}</button>
							</div>
							<div class="input_data_result pt-20">
								<span class="result_name">{{  $language.nft.withdraw_gab_fee }}</span>
								<strong class="data">{{ item.tranmsn_commis }}<em class="pl-5 unit">{{ text_unit }}</em></strong>
							</div>
						</div>
					</div>
					<div class="btn_wrap">
						<button class="pop_btn btn_fill_gray" @click="$emit('cancel')">{{  $language.common.cancel }}</button>
						<button
							class="pop_btn btn_fill_blue" @click="postWithdrawal"
							:disabled="disabled"
						>{{  $language.common.withdraw_apply_on }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>

export default {
	name: 'mafia033'
	, props: ['rules', 'wallet', 'user', 'blockchain_div_code']
	, data: function(){
		return {
			item: {
				address: ''
				, amount: ''
				, tranmsn_commis: 0
			}
			, tranmsn_commis: 0
			, walletwallet_address: ''
			, is_view: false
		}
	}
	, computed: {
		disabled: function(){
			let t = true

			if(this.item.address && this.item.amount > 0){
				t = false
			}

			return t
		}
		, text_unit: function(){
			let t = ''
			switch (this.wallet.coin_token_code){
				case 'BC00200002':
					t = 'KLAY'
					break
				case 'BC00200001':
					t = 'ETH'
					break
				case 'BC00200003':
					t = 'MAF'
					break
				case 'BC00200004':
					t = 'MAF'
					break
				case 'BC00200005':
					t = 'REAP'
					break
			}
			return t
		}
	}
	, methods: {
		postWithdrawal: function(){
			if(!this.item.address){
				// console.log('!this.item.address')
				return this.$bus.$emit('notify', { type: 'error', message: this.$language.nft.address_withdraw_require})
			}else if(!this.item.amount) {
				// console.log('!this.item.amount')
				return this.$bus.$emit('notify', {type: 'error', message: this.$language.nft.quantity_withdraw_require})
			}else if(Number(this.item.amount) <= 0){
				// console.log('Number(this.item.amount) <= 0')
				return this.$bus.$emit('notify', { type: 'error', message: this.$language.nft.quantity_withdraw_check})
			}else if(this.item.amount > this.wallet.amount ) {
				// console.log('this.item.amount > this.wallet.amount')
				return this.$bus.$emit('notify', { type: 'error', message: this.$language.nft.quantity_withdraw_lack})
			}else{
				// console.log('click')
				this.$emit('click', this.item)
			}
		}
		, max: function(){
			this.item.amount = this.wallet.holding_coin_quantity
		}
		, getWalletAddress: async function(){
			try {
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_address
					, data: {
						member_number: this.user.member_number
						, blockchain_div_code: this.blockchain_div_code
					}
					, type: true
				})

				if(result.success){
					this.is_view = true
					this.$set(this.wallet, 'address', result.data.wallet_address)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.is_view = false
				this.$emit('cancel')
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getTransCommis: async function(){
			try{

				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_trans_commis
					,data: {
						member_number: this.user.member_number
						, blockchain_div_code: this.wallet.blockchain_div_code
						, coin_token_code: this.wallet.coin_token_code
						, receive_wallet_address: this.item.address
					}
					,type: true
				})

				if(result.success){
					this.item.tranmsn_commis = result.data.tranmsn_commis
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}

	, async created() {
		// console.log('wallet', this.wallet)
		await this.getWalletAddress()
	}
}

</script>