<template>
	<div
		v-if="wallet.length > 0"
		class="section_wrap pt-140 pb-80"
	>
		<div class="container">
			<div class="row">
				<section>
					<div class="cont_box_3 mt-10" style="">
						<div
							v-if="item.coin_token_code"
							class="box_text"
						>
							<!-- TODO : 해당 코인 심볼 .eht .klay -->
							<div
								class="wallet_coin"  style="height: 100px; overflow: hidden"
							>
								<img
									:src="$request.upload_url(item.coin_token_code)"
								>
								<p class="wallet_coin" >
									<strong class="co_price" style="font-size: 1.6rem !important;" >{{  item.holding_coin_quantity | toFixed(8) }}</strong><em class="co_unit" style="font-size: 1.6rem !important;">{{  item.label }}</em>
								</p>
							</div>
							<p class="mt-20">
								<button
									class="btn_l btn_fill_blue mr-10" style="padding: 0 2rem; font-size: 1.5rem"
									@click="is_035 = !is_035"
								>{{  $language.common.deposit }}</button>
								<button
									class="btn_l btn_fill_blue" style="padding: 0 2rem; font-size: 1.5rem"
									@click="toWidthrawal"
								>{{  $language.common.withdraw }}</button>
							</p>
						</div>
						<div
							v-else
							class="box_text"
						>
							<div
								class="wallet_coin"  style="height: 100px; overflow: hidden"
							>
								<img
									:src="require('@/assets/image/intro_character_2.png')"
									style="width: 120px"
								>
							</div>
							<p class="mt-10 pa-20 color-white size-px-14">
								{{ $language.nft.wallet_require}} <!-- 지갑 생성 후 이용가능합니다 -->
							</p>
						</div>
					</div>

					<div
						class="nft_tab type1 mt-10"
					>
						<a
							class="left"
							:class="{ focus: blockchain_div_code == 'BC00100001'}"
							@click="setBlockchain('BC00100001')"
						>KLAY</a>
						<a
							class="center"
							:class="{ focus: blockchain_div_code == 'BC00100002'}"
							@click="setBlockchain('BC00100002')"
						>ETH</a>
						<a
							class="right"
							:class="{ focus: blockchain_div_code == 'BC00100003'}"
							@click="setBlockchain('BC00100003')"
						>MATIC</a>
					</div>

					<div class="wallet_wrap">
						<ul class="wallet_list">
							<template
								v-for="(address, index) in items_wallet"
							>
								<li
									v-if="address.blockchain_div_code == blockchain_div_code"
									:key="'address_' + index"

									@click="setItem(address)"
								>
									<div
										class="wallet_item"
										:class="{ disable: address.coin_token_code != item.coin_token_code }"
									>
										<div class="wall_profile">
											<img
												:src="$request.upload_url(address.coin_token_code)" :alt="address.coin_token_name"
											>
										</div>
										<div class="wall_info">
											<p class="wall_info_coin">
												<strong class="co_price" style="font-size: 1.4rem !important;">{{  address.holding_coin_quantity | toFixed(2)}}</strong><em class="co_unit" style="font-size: 1.4rem !important;">{{  address.label }}</em>
											</p>
											<a @click="to032(address)" class="wall_info_link">{{  $language.reset.transfer_list }}</a>
										</div>
									</div>
								</li>
							</template>
						</ul>
					</div>
					<div
						class="btn_center pt-30"
					>
						<button
							class="btn_center btn_l btn_fill_blue "
							:class="{ disable: is_able }"
							:disabled="is_able"

							@click="is_0301 = true"
						>{{  $language.nft.create_wallet }}</button>
					</div>
				</section>
			</div>
		</div>

		<mafia0301
			v-if="is_0301"
			:currencies="currencies"

			@click="postWallet"
			@cancel="is_0301 = false"
		></mafia0301>

		<!-- 출금 -->
		<mafia033
			v-if="is_033"
			:rules="rules"
			:wallet="item"
			:user="user"
			:blockchain_div_code="item.blockchain_div_code"

			@cancel="is_033 = false"
			@click="onPin"
		></mafia033>

		<mafia0331
			v-if="is_0331"
			:rules="rules"
			:wallet="item"
			:user="user"

			@cancel="is_0331 = false"
			@click="onPin"
		></mafia0331>

		<mafia035
			v-if="is_035"

			:user="user"
			:blockchain_div_code="item.blockchain_div_code"
			:token="item"

			@click="postDeposit"
		></mafia035>

		<popup_confirm
			v-if="is_confirm"
			@click="toOtp"
			@cancel="is_confirm = false"
		>
			<template v-slot:title>{{  $language.auth.two_factor_info }}</template>
			<template
				v-slot:main-txt
			>{{  $language.auth.two_factor_status_non_register }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.auth.two_factor_require_google }}</template>
			<template
				v-slot:name-cancel
			>{{  $language.common.cancel }}</template>
			<template
				v-slot:name-confirm
			>{{  $language.common.setting }}</template>
		</popup_confirm>

		<PIN
			v-if="is_pin"
			:options="pin_option"
			:user="user"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 1004"

			@click="onOtp"
			@cancel="is_pin = false"
			@goBack="is_pin = false"
		></PIN>

		<wallet_otp
			v-if="is_otp"
			:user="user"

			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 1004; background-color: white"
			@click="postWithdrawal"
			@cancel="is_otp = false"
			@goBack="is_otp = false"
		></wallet_otp>

	</div>

	<div
		v-else
		class="flex_wrap"
	>
		<div class="pt-140 pb-80 intro_content">
			<div class="intro_top character3">
				<div class="text_guide pt-20">
					<h4>{{  $language.nft.no_wallet }}</h4>
				</div>
				<div class="text_guide_desc pt-5">
					<p>{{  $language.common.nft_wallet_require_02 }}</p>
				</div>
				<div
					class="btn_center pt-30"
				>
					<button class="btn_center btn_l btn_fill_blue" @click="openWallet">{{  $language.nft.create_wallet }}</button>
				</div>
			</div>
		</div>

		<mafia0301
			v-if="is_0301"
			:user="user"
			:currencies="currencies"
			:wallet="wallet"

			@click="postWallet"
			@cancel="is_0301 = false"
		></mafia0301>
	</div>
</template>

<script>
	import mafia0301 from "@/view/Wallet/mafia030-1";
	import mafia035 from "@/view/Wallet/mafia035";
	import Mafia033 from "@/view/Wallet/mafia033";
	import mafia0331 from "@/view/Wallet/mafia033-1";

	import PIN from "@/view/Auth/mafia007"
	import wallet_otp from "@/view/Wallet/wallet_otp";
	import popup_confirm from "@/view/Layout/PopupConfirm";

	export default {
		name: 'mafia30'
		, props: ['Axios', 'sample', 'rules', 'user']
		, components: {popup_confirm, wallet_otp, Mafia033, mafia0301, mafia035, PIN, mafia0331}
		, data: function(){
			return {
				program: {
					name: '지갑'
				}
				, is_0301: false
				, currencies: [
					{ blockchain_div_code: 'BC00100001', name: 'KLAY'}
					, { blockchain_div_code: 'BC00100002', name: 'ETH'}
					, { blockchain_div_code: 'BC00100003', name: 'MATIC'}
				]
				, wallet: []
				, item: {
					blockchain_div_code: ''
					, coin_token_code: ''
					, label: ''
					, holding_coin_quantity: 0
				}
				, item_withdrawal: {

				}
				, is_032: false
				, is_033: false
				, is_0331: false
				, is_035: false
				, is_pin: false
				, is_otp: false
				, pin_number: ''
				, pin_option: {
					pin_type: 'check'
				}
				, item_address: {

				}
				, is_confirm: false
				, wallet_type: 'eth'
				, blockchain_div_code: 'BC00100002'
			}
		}

		,computed:{
			is_able: function(){
				let t = false

				if(this.wallet.length > 4){
					t = true
				}
				return t
			}
			, items_wallet: function(){
				return this.wallet.filter((item) => {

					if(item.coin_token_code == 'BC00200002'){
						item.label = 'KLAY'
						this.currencies.filter( (currency) => {
							if(currency.blockchain_div_code == item.blockchain_div_code){
								currency.is_use = true
							}
						})
					}else if(item.coin_token_code == 'BC00200003'){
						item.label = 'MAF'

						this.currencies.filter( (currency) => {
							if(currency.blockchain_div_code == item.blockchain_div_code){
								currency.is_use = true
							}
						})
					}else if(item.coin_token_code == 'BC00200001'){
						item.label = 'ETH'

						this.currencies.filter( (currency) => {
							if(currency.blockchain_div_code == item.blockchain_div_code){
								currency.is_use = true
							}
						})
					}else if(item.coin_token_code == 'BC00200005'){
						item.label = 'REAP'

						this.currencies.filter( (currency) => {
							if(currency.blockchain_div_code == item.blockchain_div_code){
								currency.is_use = true
							}
						})
					}else if(item.coin_token_code == 'BC00200006'){
						item.label = 'MATIC'

						this.currencies.filter( (currency) => {
							if(currency.blockchain_div_code == item.blockchain_div_code){
								currency.is_use = true
							}
						})
					}

					return item
				})
			}
			, list_currency: function(){
				let t = this.currencies



				return t
			}
		}

		, methods: {
			getWalletList: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						,url: this.$api_url.api_path.get_wallet_list
						,data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.wallet = result.data.wallet_list
						if(this.wallet.length > 0){
							this.setBlockchain(this.wallet[0].blockchain_div_code)
							this.wallet.filter( (item) => {
								if(item.blockchain_div_code == 'BC00100002'){
									// this.setBlockchain(item.blockchain_div_code)
								}
							})
						}
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getCurrencies: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'get'
						,url: this.$api_url.api_path.get_currency
						,data: {
							type: 'currency'
						}
					})

					if(result.success){
						this.currencies = result.data
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postWallet: async function(currency){
				currency.user_id = this.user.id
				try{
					if(!currency.blockchain_div_code){
						throw this.$language.nft.choice_create_wallet
					}
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_wallet
						, data: {
							member_number: this.user.member_number
							, blockchain_div_code: currency.blockchain_div_code
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('notify',  { type: 'success', message: result.message})
						this.is_0301 = false
						await this.getWalletList()
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,onHistory: function(address){
				this.is_032 = true
				this.item_address = address
			}
			,postWithdrawal: async function(otp){
				let item = this.item_withdrawal
				try{
					item.currency_id = this.item.currency_id
					this.$bus.$emit('on', true)
					if(item.address == ''){
						this.$bus.$emit('notify', { type: 'error', message: this.$language.nft.address_withdraw_require_02})
						return false
					}
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_withdrawal
						, data: {
							member_number: this.user.member_number
							, coin_token_code: this.item.coin_token_code
							, receive_wallet_address: this.item_withdrawal.address
							, receive_coin_quantity: this.item_withdrawal.amount
							, tranmsn_commis: this.item_withdrawal.tranmsn_commis
							, pinnumber: this.pin_number
							, gogle_otp_certfc_number: otp
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
						await this.getWalletList()
						this.items_wallet.filter((item) => {
							if(this.item.coin_token_code == item.coin_token_code){
								this.item = item
							}
						})
						this.is_033 = false
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_pin = false
					this.is_otp = false
				}
			}

			,postDeposit: function(){

				this.is_035 = false
			}
			, onPin: function(item){
				this.item_withdrawal = item
				this.is_pin = true
			}
			, onOtp: function(pin){
				this.pin_number = pin
				this.is_pin = false
				this.is_otp = true
			}
			, toOtp: function(){
				this.$bus.$emit('to', { name: 'mafia1113'})
			}
			, toWidthrawal: function(){
				if(this.user.gogle_otp_reg_fg == 'Y'){
					if(this.item.coin_token_code == 'BC00200003'){
						// this.is_0331 = true
						this.is_033 = true
					}else{
						this.is_033 = true
					}

				}else{
					this.is_confirm = true
				}
			}
			, to032: function(address){
				this.$emit('to', { name: 'mafia032', params: { id: address.coin_token_code }})
			}
			, setItem: function(address){
				this.item = address
			}
			, setBlockchain: function(code){

				if(this.blockchain_div_code != code){

					this.blockchain_div_code = code
					this.item = {}
					this.items_wallet.filter((item) => {
						if(item.blockchain_div_code == code && (item.coin_token_code == 'BC00200001' || item.coin_token_code == 'BC00200002' || item.coin_token_code == 'BC00200006')){
							this.item = item
						}
					})
				}
			}
			, openWallet: function(){

				if(this.user.mphone_self_certfc_fg == 'Y'){
					this.is_0301 = true
				}else{
					this.$bus.$emit('onPersonalConfirm', {name: 'Wallet'})
				}
			}
		}

		,async created() {
			this.$emit('onLoad', this.program)

			//await this.getCurrencies()
			await this.getWalletList()
		}

		, watch: {
			$route(){
				if(this.$route.query.is_new == 1){
					if(this.items_wallet.length == 0){
						this.is_0301 = true
					}
				}
			}
		}

	}
</script>