<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article class="full_view">
					<div class="view_cont_wrap">
						<h3 class="pop_tit">{{  $language.nft.create_wallet }}</h3>

						<div
							class="size-px-13"
						>
							{{  $language.common.will_create }}
						</div>
						<div
							class="follow_list_wrap"
						>
							<ul>
								<li
									v-for="(currency, index) in currencies"
									:key="'currency_' + index"
								>
									<div
										v-if="!currency.is_use"
										class="bright_radio"
									>
										<input
											:value="currency.blockchain_div_code"
											type="radio" :id="'item_' + index" name="follower"
											@click="item = currency"
										>
										<label :for="'item_' + index">{{  currency.name  }}</label>
									</div>
									<div
										v-else
										class="bright_radio"
									>
										<input
											type="radio" :id="'item_' + index" name="follower"
											disabled
										>
										<label :for="'item_' + index">{{  currency.name  }}</label>
									</div>
								</li>
							</ul>
						</div>

					</div>
					<div class="btn_wrap">
						<button class="pop_btn btn_fill_gray" @click="$emit('cancel')">{{  $language.common.cancel }}</button>
						<button
							class="pop_btn btn_fill_blue"
							@click="$emit('click', item)"
							:disabled="!item.blockchain_div_code"
						>{{  $language.common.ok }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
	export default {
		name: 'mafia0301'
		, props: ['currencies', 'wallet', 'user', 'blockahin_div_code']
		, data: function (){
			return {
				item: {

				}
			}
		}
		,methods: {
			getWalletAddress: async function(){
				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_wallet_address
						, data: {
							member_number: this.user.member_number
							, blockahin_div_code: this.blockahin_div_code
						}
						, type: true
					})

					if(result.success){
						this.item = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
				}
			}
		}

		, created() {
			// this.getWalletAddress()
		}
	}
</script>